<template>
  <div class="answer-text">
    <div class="khsrl">
      {{ $t('com.report.interviewRecord.text004') }}：
      <span v-if="assessmentPoint">{{ assessmentPoint }}</span>
    </div>
    <el-divider />
    <div class="khsrl">
      {{ $t('com.report.interviewRecord.text046') }}<span class="hsspan">( {{ $t('com.report.interviewRecord.text56') }} )</span>
    </div>
    <div class="rich-text" v-html="richText"></div>
  </div>
</template>
<script>
import { charToUnicode, unicodeToChar } from '@/utils'

export default {
  data() {
    return {}
  },

  props: {
    answerText: { type: String, required: true, default: '' },
    language: { type: Number, required: false, default: 0 },
    isCustom: { type: Number, required: false, default: 0 },
    keyPointInfoResult: {
      type: Array,
      default() {
        return []
      },
    },
    assessmentPoint: { type: String, required: false, default: '' },
  },

  computed: {
    richText() {
    if (this.isCustom) {
      return this.answerText
    }

    if (this.keyPointInfoResult && this.keyPointInfoResult.length) {
      console.log('keyPointInfoResult', this.keyPointInfoResult)
      let str = this.answerText
      let symbols = ',;!.?，；。！？' //句子分隔符
      let symbolList = []

      for (let i = 0; i < symbols.length; i++) {
        const symbol = symbols.charAt(i)
        symbolList.push(symbol)
      }

      //根据符号分割句子
      const list = str.split(/,|;|!|\.|\?|，|；|。|！|？/g)
      const symbolIndex = []

      //记录标点符号的对应句子的位置
      for (let i = 0; i < this.answerText.length; i++) {
        const chart = this.answerText.charAt(i)
        if (symbols.indexOf(chart) !== -1) {
          symbolIndex.push(chart)
        }
      }

      let sentenceList = []
      //过滤包含关键词的句子的信息
      this.keyPointInfoResult.forEach((item) => {
        list.forEach((sentence, index) => {
          const score = item.keyScore || 0
          const key = item?.keyPoint || ''
          if (this.isHitKey(sentence, key)) {
            const info = sentenceList[index]
            if (info) {
              info.data.push({
                key,
                score,
                color: item.highLightColor || '',
              })
            } else {
              sentenceList[index] = {
                value: sentence,
                index,
                data: [
                  {
                    key,
                    score,
                    color: item.highLightColor || '',
                  },
                ],
              }
            }
          }
        })
      })

      //处理包含正负向关键词的句子，对句子标记颜色
      sentenceList.forEach((info) => {
        if (info) {
          const sentence = info.value
          const scoreList = info.data || []
          const index = info.index
          let isDue = false //是否正向
          let isNegative = false //是否反向

          scoreList.forEach((data) => {
            const score = data.score
            if (score > 0) {
              isDue = true
            } else if (score < 0) {
              isNegative = true
            }
          })

          //对句子中不同时存在正向和负向的内容标记颜色
          if ((isDue && !isNegative) || (!isDue && isNegative)) {
            let color = isDue ? '#8DEBF7' : '#FFCA3A'
            const style = '"background-color: ' + color + ';"'
            list[index] = charToUnicode(`<span style=${style}>${sentence}</span>`)
          }
        }
      })
      sentenceList
        .filter((info) => {
          return !info.isSign
        })
        .forEach((info) => {
          const index = info.index
          const scoreList = (info.data || []).filter((data) => {
            return data.score === 0
          })

          scoreList.forEach((data) => {
            list[index] = this.getRichText(list[index], data)
          })
        })

      let finalStr = ''
      list.forEach((item, index) => {
        finalStr += item + (symbolIndex[index] || '')
      })
      return unicodeToChar(finalStr)
    } else {
      return this.answerText
    }

    // return str
    },
  },
  
  methods: {
    isHitKey(answerText = '', keyWord) {
      // window.console.log('isHitKey',answerText)
      let isHit = false
      if (this.language === 2) {
        //英文回答匹配
        let str = answerText.trim() //charToUnicode(this.answerText)
        const unkeys = ['not', 'Not', 'n\'t']

        const re = /[^a-zA-Z]/
        if (str === keyWord) {
          return true
        }

        if (str) {
          const codeK = keyWord // charToUnicode(k)
          let p = str.indexOf(codeK)
          const arr = []
          while (p > -1) {
            arr.push(p)
            p = str.indexOf(codeK, p + 1)
          }

          arr.forEach((pos) => {
            //字符串所在起始位置往前一位
            const start = pos - 1
            //字符串所在结束位置往后一位
            let end = pos + codeK.length
            // if (end === str.length) {
            //   end = end - 1
            // }
            console.log('str[end]', str[end], '-', !str[end], '-re', re.test(str[end]))
            console.log('str[start]', str[start], '-', !str[start], '-re', re.test(str[start]))
            console.log((!str[end] || re.test(str[end])) && (!str[start] || re.test(str[start])))

            if ((!str[end] || re.test(str[end])) && (!str[start] || re.test(str[start]))) {
              //命中关键词
              isHit = true
            }
            if (pos > 2) {
              let startTmp = start
              while (re.test(str[startTmp - 1]) && startTmp - 1 > 0) {
                startTmp = startTmp - 1
              }
              const key1 = str[startTmp - 3] + str[startTmp - 2] + str[startTmp - 1]
              console.log('key1', key1, 'isUnkeys', unkeys.includes(key1))
              isHit = unkeys.includes(key1) ? false : isHit
              // if (str[start - 4] && re.test(str[start - 4])) {
              //   isHit = true
              // } else {
              //   isHit = !unkeys.includes(key1)
              // }
            }
          })
        }
        // console.log('isHitKey', answerText, keyWord, 'isHit', isHit)
        return isHit
      } else {
        return answerText.indexOf(keyWord) > -1
      }
    },
    getRichText(answerText = '', keyInfo) {
    // let s = ''
    const keyWord = keyInfo.key
    const highLightColor = keyInfo.color
    if (this.language === 2) {
      //英文回答匹配
      let str = answerText.trim() //charToUnicode(this.answerText)
      const re = /[^a-zA-Z]/
      const unkeys = ['not', 'Not', 'n\'t']
      if (str) {
        const codeK = keyWord // charToUnicode(k)
        let p = str.indexOf(codeK)
        const arr = []
        while (p > -1) {
          arr.push(p)
          p = str.indexOf(codeK, p + 1)
        }

        let strArr = []
        const arrLen = arr.length || 0
        arr.forEach((pos, index) => {
          const start = pos - 1
          let end = pos + codeK.length

          const style = highLightColor
            ? '"background-color:' + highLightColor + ';  white-space: pre;"'
            : '"background: #80aeff; color: #ffffff; white-space: pre;"'
          let isHit = false
          if ((!str[end] || re.test(str[end])) && (!str[start] || re.test(str[start]))) {
            //命中关键词
            isHit = true
          }
          if (pos > 2) {
            let startTmp = start
            while (re.test(str[startTmp - 1]) && startTmp - 1 > 0) {
              startTmp = startTmp - 1
            }
            const key1 = str[startTmp - 3] + str[startTmp - 2] + str[startTmp - 1]
            isHit = unkeys.includes(key1) ? false : isHit
            // if (str[start - 4] && re.test(str[start - 4])) {
            //   isHit = true
            // } else {
            //   isHit = unkeys.includes(key1) ? false : isHit
            // }
          }

          let replace = isHit
            ? `${charToUnicode(` <span style=${style}>`)}${codeK}${charToUnicode('</span> ')}`
            : ` ${codeK}`
          if (index === 0) {
            strArr.push(str.substring(0, start))
          }
          if (index + 1 === arrLen) {
            console.log('end', str.substring(end))
            strArr.push(replace + str.substring(end))
          }
          if (index + 1 < arrLen) {
            strArr.push(replace + str.substring(end, arr[index + 1] - 1))
          }
        })

        if (arrLen) {
          str = strArr.join('')
        } else {
          str = `${str}`
        }
      }
      return str
    } else {
      let str = answerText
      console.log('str', str)
      const reg = new RegExp(keyWord, 'g')

      const style = highLightColor
        ? '"background-color:' + highLightColor + ';white-space: pre;"'
        : '"background: #80aeff;color: #ffffff;"'
      const spanL = charToUnicode(`<span style=${style}>`)
      const spanR = charToUnicode('</span>')
      const rep = spanL + keyWord + spanR
      str = str.replace(reg, rep)

      return str
    }
  }
  }
}
</script>
<style lang="scss" scoped>
.answer-text {
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  color: #333333;
  // line-height: 22px;
  .khsrl {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    line-height: 24px;
    .hsspan {
      font-weight: 400;
      margin-left: 8px;
    }
  }
  .el-divider {
    margin: 8px 0px;
  }
  .rich-text {
    word-break: break-word;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-top: 3px;
  }
}
</style>
