<template>
	<div class="report-detail">
		<!-- <van-nav-bar :title="`${interviewName}的评估`" left-arrow @click-left="onClickLeft" /> -->
		<section v-if="!sleep">
			<van-tabs v-model="active" line-width="15">
				<van-tab title="主观题" v-if="subQuestion.length > 0">
					<!-- 视频题 -->
					<van-panel class="question" :title="getQuestionType(answer_method)" v-if="answer_method === 1 || answer_method === 6">
						<van-row type="flex" class="question comlun">
							<div class="video-title problem-video" v-html="currentQuestion.question.problem"></div>
							<div style="margin: 10px 10px 10px 0px">
								<span>回答：</span>
								<!-- <span v-if="currentQuestion.question.is_not_score && currentQuestion.question.is_not_score === 1">( 本题不计分 )</span> -->
							</div>
							<!-- <van-col v-for="(video, i) in currentQuestion.video_url" :key="i"> -->
							<video
								:src="currentQuestion.video_url[0].video_url_mp4 ? currentQuestion.video_url[0].video_url_mp4 : currentQuestion.video_url[0].video_url"
								:poster="
									(currentQuestion.video_url[0].video_url_mp4 ? currentQuestion.video_url[0].video_url_mp4 : currentQuestion.video_url[0].video_url) +
									'?x-oss-process=video/snapshot,t_0000,f_jpg,w_0,h_0,m_fast'
								"
								controls="false"
							>
								您的浏览器不支持 video 标签。
							</video>
							<!-- </van-col> -->
						</van-row>
					</van-panel>
					<!-- 论述题 -->
					<van-panel class="question" :title="getQuestionType(answer_method)" v-if="answer_method === 0 || answer_method === 7">
						<div class="obj-title problem-video" v-html="currentQuestion.question.problem"></div>
						<div class="obj-answer">
							<div>
								<span>回答：</span>
								<!-- <span v-if="currentQuestion.question.is_not_score && currentQuestion.question.is_not_score === 1">( 本题不计分 )</span> -->
							</div>
							<pre class="answer" v-html="currentQuestion.question.answer">{{ currentQuestion.question.answer }}</pre>
						</div>
					</van-panel>
					<van-col>
						<van-pagination force-ellipses class="obj-pagination" @change="pageChange" v-model="currentPage" :total-items="subQuestion.length" :items-per-page="1" />
					</van-col>
				</van-tab>
				<van-tab title="客观题" v-if="objectQuestion.length > 0">
					<van-panel class="question" :title="`${index + 1}.${getQuestionType(q.question.problem_answer_method)}`" v-for="(q, index) in objectQuestion" :key="index">
						<div class="sub-title gray problem-video" v-html="q.question.problem"></div>
						<div class="sub-option gray" v-if="q.question.problem_answer_method != 4">
							<div v-for="(op, ind) in q.questionOptionList" :key="ind">{{ op.optionCode }}、{{ op.optionContent }}</div>
						</div>
						<div class="sub-answer">
							<div>
								<span>回答：</span>
								<span :class="{ green: q.question.objective_score === 1, red: !q.question.objective_score, yellow: q.question.objective_score === 2 }">{{ q.question.answer }}</span>
							</div>
							<div class="line-20">
								<span>{{ q.question.objective_score | rightText }}</span>
								<!-- <span v-if="currentQuestion.question.is_not_score && currentQuestion.question.is_not_score === 1">( 本题不计分 )</span> -->
							</div>
							<!-- <div>{{ q.question.objective_score | rightText }}</div> -->
							<div class="line-20">
								<span>正确答案：</span><span> {{ q.question.right_key | RightKey }}</span>
								<!-- <span></span> -->
							</div>
						</div>
					</van-panel>
				</van-tab>

				<van-tab title="编程题" v-if="codeQuestion.length > 0">
					<!-- 编程 -->
					<van-panel class="question" :title="getQuestionType(code_answer_method)" v-if="code_answer_method === 7">
						<div class="obj-title problem-video" v-html="currentQuestionCode.question.problem"></div>
						<div class="obj-answer">
							<div>
								<span>回答：</span>
								<!-- <span v-if="currentQuestionCode.question.is_not_score && currentQuestionCode.question.is_not_score === 1">( 本题不计分 )</span> -->
							</div>
							<pre class="answer">{{ currentQuestionCode.question.answer }}</pre>
						</div>
					</van-panel>
					<van-col>
						<van-pagination force-ellipses class="obj-pagination" @change="pageChangeCode" v-model="currentPageCode" :total-items="codeQuestion.length" :items-per-page="1" />
					</van-col>
				</van-tab>
				<van-tab title="全程屏幕录制" v-if="fullRecoreds.length > 0">
					<!-- 视频题 -->
					<van-row type="flex" class="score comlun">
						<div class="test">此处为候选人AI面试的全程屏幕录制视频，若候选人参与AI面试过程中网络不佳或多次退出刷新面试，此处可能出现多段视频。请您知悉</div>
						<van-col v-for="(video, i) in fullRecoreds" :key="i">
							<div>录制时间：{{ moment(video.createdDate).format('YYYY-MM-DD HH:mm:ss') }}</div>
							<video :src="video.videoUrl" controls="controls" :poster="video.videoUrl + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast'">您的浏览器不支持 video 标签。</video>
						</van-col>
					</van-row>
				</van-tab>
			</van-tabs>
		</section>
	</div>
</template>

<script>
import { findId, interviewInfo } from '../api/report'
import moment from 'moment'
export default {
	props: [],
	data() {
		return {
			moment: moment,
			objectQuestion: [], // 客观题
			subQuestion: [], //主观题
			codeQuestion: [], // 编程题
			currentPage: 1,
			fullRecoreds: [],
			currentPageCode: 1,
			active: 0,
			interviewId: '',
			companyId: '',
			positionId: '',
			answerList: [],
			interviewName: '',
			questionTypeAry: ['论述题', '视频题', '单选题', '多选题', '判断题', '排序题', '口音题', '编程题'],
			currentQuestion: { question: {}, video_url: [{}] }, //主观题区域题目
			currentQuestionCode: { question: {}, video_url: [{}] }, // 编程题区域题目
			sleep: false,
		}
	},
	mounted() {
		const param = this.$route.query
		this.interviewId = param.id
		this.findId()
	},
	watch: {
		active() {
			this.sleep = true
			this.$nextTick(() => {
				this.sleep = false
				this.filterVideo()
			})
		},
		currentQuestion() {
			this.sleep = true
			this.$nextTick(() => {
				this.sleep = false
				this.filterVideo()
			})
		},
	},
	methods: {
		listenVideo() {
			let videoArray = document.getElementsByTagName('video')
			if (videoArray && videoArray.length) {
				for (let i = 0; i < videoArray.length; i++) {
					videoArray[i].onplay = () => {
						for (let j = 0; j < videoArray.length; j++) {
							if (i !== j) {
								videoArray[j].pause()
							}
						}
					}
				}
			}
		},
		filterVideo() {
			console.log('filterVideo')
			this.$nextTick(() => {
				let containnerArray = document.getElementsByClassName('problem-video')
				if (containnerArray && containnerArray.length) {
					for (let i = 0; i < containnerArray.length; i++) {
						let videoArray = containnerArray[i].getElementsByTagName('video')
						if (videoArray && videoArray.length) {
							for (let j = 0; j < videoArray.length; j++) {
								let childNode = videoArray[j]
								childNode.setAttribute('width', '100%')
								childNode.setAttribute('height', '200px')
								childNode.setAttribute('style', 'background-color:#000;')
								childNode.setAttribute('poster', childNode.getAttribute('src') + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast')
							}
						}
					}
				}
				//移除a标签下载链接
				let classArray = document.getElementsByClassName('question')
				if (classArray && classArray.length) {
					for (let i = 0; i < classArray.length; i++) {
						let content = classArray[i]
						let tags = content.getElementsByTagName('a')
						for (let index = 0; index < tags.length; index++) {
							const element = tags[index]
							element.setAttribute('href', '#')
							element.removeAttribute('download')
							element.removeAttribute('target')
						}
					}
				}

				this.listenVideo()
			})
		},
		onClickLeft() {
			this.$router.back()
		},
		findId() {
			let userId = this.interviewId
			findId({ userId }).then(res => {
				this.companyId = res.data.company_id || ''
				this.positionId = res.data.positionid || ''
				this.interviewInfo()
			})
		},
		interviewInfo() {
			const data = {
				userId: this.interviewId,
				companyId: this.companyId,
				positionId: this.positionId,
			}
			interviewInfo(data).then(res => {
				if (res.code === 0) {
					this.interviewName = res.data.interviewResult.name
					this.answerList = res.data.answerResult
					this.fullRecoreds = res.data.fullRecords || []

					this.classify(this.answerList)
					this.filterVideo()
				}
			})
		},
		classify(list) {
			let tempArray = []
			for (var i = 0; i < list.length; i++) {
				tempArray.push(list[i])
			}
			let subarr = [0, 1, 6]
			let objarr = [2, 3, 4, 5]
			let codearr = [7]
			tempArray.forEach(t => {
				if (subarr.includes(t.question.problem_answer_method)) {
					this.subQuestion.push(t)
				}
				if (objarr.includes(t.question.problem_answer_method)) {
					this.objectQuestion.push(t)
				}
				if (codearr.includes(t.question.problem_answer_method)) {
					this.codeQuestion.push(t)
				}
			})
			if (this.subQuestion.length > 0) {
				this.currentQuestion = this.subQuestion[0]
			}
			if (this.codeQuestion.length > 0) {
				this.currentQuestionCode = this.codeQuestion[0]
			}
		},
		pageChange() {
			this.currentQuestion = this.subQuestion[this.currentPage - 1]
		},
		pageChangeCode() {
			this.currentQuestionCode = this.codeQuestion[this.currentPageCode - 1]
			console.log(this.currentQuestionCode)
		},
		getQuestionType(q) {
			let arr = this.questionTypeAry.find((item, index) => q === index)
			return arr
		},
	},
	computed: {
		answer_method() {
			return this.currentQuestion.question.problem_answer_method
		},
		code_answer_method() {
			return this.currentQuestionCode.question.problem_answer_method
		},
	},
	filters: {
		RightKey(rightKey) {
			if ('true' === rightKey) {
				return '正确'
			} else if ('false' === rightKey) {
				return '错误'
			}
			return rightKey
		},
		rightText(key) {
			switch (key) {
				case 1:
					return '回答正确'
				case 2:
					return '回答不全'
				default:
					return '回答错误'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.report-detail {
	/deep/ video {
		width: 100%;
		height: 200px;
		margin: 10px 0;
	}
	.score {
		padding: 0 20px;
		div {
			color: #666;
		}
		.test {
			margin: 10px 0;
			line-height: 20px;
		}
	}
	section {
		height: calc(100vh - 70px);
		overflow-y: auto;
		overflow-x: hidden;
		.gray {
			color: #666666;
		}
		.green {
			color: #6ed401;
		}
		.red {
			color: #ff0000;
		}
		.yellow {
			color: #f4d75e;
		}
		.line-20 {
			line-height: 20px;
		}
		.video {
			width: 100%;
			height: 200px;
			margin: 10px 0;
		}
		pre {
			word-break: break-word;
			white-space: pre-wrap;
		}

		/deep/ .van-tabs {
			.van-tabs__line {
				background: #1890ff;
			}
			.van-tab--active {
				font-weight: 550;
			}
			.van-tabs__content {
				padding-bottom: 40px;
			}
		}
		.comlun {
			flex-direction: column;
			justify-content: space-between;
		}
		/deep/ .obj-pagination {
			justify-content: center;
			position: fixed;
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			.van-pagination__page {
				flex-grow: 1;
			}
			li:last-child {
				display: none;
			}
			li:first-child {
				display: none;
			}
			li {
				cursor: pointer;
				//display: block;
				width: 40px;
				height: 40px;
				border-radius: 3px;
				margin: 0 8px;
				border: 1px solid rgba(221, 221, 221, 1);
			}
		}
		.video-title {
			padding: 0 20px;
		}
		.obj-title {
			// padding: 0 20px;
			font-size: 12px;
			color: #666666;
		}
		.obj-answer {
			// margin: 0 20px;
			border: 1px dashed #dddddd;
			border-radius: 5px;
			padding: 12px 16px;
			padding: 14px;
			div:first-child {
				color: #333333;
				font-weight: 500;
				// font-size: 14px;
				// margin-bottom: 10px;
			}
		}
		.sub-answer {
			border: 1px dashed #dddddd;
			border-radius: 5px;
			padding: 12px 16px;
			margin-top: 10px;
			div:first-child {
				color: #333333;
				font-weight: 500;
				// font-size: 14px;
				// margin-bottom: 10px;
			}
		}
		/deep/.van-panel {
			.van-panel__header {
				padding-top: 14px;
				padding-bottom: 14px;
				.van-cell__title {
					font-size: 15px;
					color: #333333;
					font-weight: 550;
				}
			}
			.van-panel__content {
				padding: 0 20px;
			}
		}
		/deep/ .van-panel::after {
			content: none;
		}
		.question {
			/deep/ img {
				max-width: 100%;
			}
			.video {
				max-width: 100%;
			}
		}
	}
}
</style>
<style lang="scss">
.obj-answer {
	pre {
		p {
			margin: 0 !important;
		}
	}
}
</style>
