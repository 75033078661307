<template>
  <div>
    <div class="read-exam-summary" v-if="questionArray && questionArray.length">
      <div class="question-detail-wrapper">
        <template v-for="(question, qIndex) in questionArray">
          <template v-for="(q, index) in Array.isArray(question) ? question : [question]">
            <div :key="qIndex + '-' + index" class="read-question_item">
              <!-- 视频题 -->
              <div class="question" data-title="视频题" v-if="q.question.problem_answer_method === 1 || q.question.problem_answer_method === 6">
                <div type="flex" class="question-wrapper comlun">
                  <div class="question-type">
                    <span v-if="q.question.problem_answer_method === 1">{{ qIndex + 1 }}.{{ $t('com.report.onlinetest.spt') }}</span>
                    <span v-else>{{ qIndex + 1 }}.{{ $t('com.report.onlinetest.kyt') }}</span>
                    <span v-if="q.question.is_not_score && q.question.is_not_score === 1"> {{ $t('com.report.onlinetest.text015') }} </span>
                    <span v-else>
                      (<span style="color: rgb(24, 144, 255)">{{ q.question.actualscore }}</span>
                      <span>/</span>
                      <span>{{ $t('com.report.onlinetest.text031', [q.question.labelscore]) }}</span
                      >)
                    </span>
                  </div>
                  <question-content :content="translateIndex(q.question.problem, qIndex + 1)" />
                  <div style="margin: 10px">
                    <span>{{ $t('com.report.onlinetest.text003') }}：</span>
                    <span v-if="(q.video_url || []).length === 0">{{ $t('com.report.onlinetest.text030') }}</span>
                  </div>
                  <div v-for="(video, i) in q.video_url" :key="i" class="video-wrapper">
                    <!-- <video
									webkit-playsinline="true"
									playsinline="true"
									x5-playsinline="true"
									width="100%"
									height="165px;"
									:ref="'answerVideo' + i"
									:src="video.video_url_mp4 ? video.video_url_mp4 : video.video_url"
									controls="controls"
									controlslist="nodownload"
									:poster="(video.video_url_mp4 ? video.video_url_mp4 : video.video_url) + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast'"
								>
									您的浏览器不支持 video 标签。
								</video> -->
                    <video-player class="player vjs-big-play-centered" :playsinline="true" @play="onPlay" :options="getPlayerOption(video)"></video-player>
                  </div>
                </div>
              </div>

              <div v-if="q.question.problem_answer_method === 0 || q.question.problem_answer_method === 7">
                <!-- 论述题 -->
                <div class="question" data-title="论述题">
                  <!-- <div class="question-content">
                    <pre v-html="translateIndex(q.question.problem, qIndex + 1)"></pre>
                  </div> -->
                  <div class="question-type">
                    <span>
                      {{ qIndex + 1 }}.
                      <template v-if="q.question.problem_answer_method === 0">
                        {{ $t('com.report.onlinetest.lst') }}
                      </template>
                      <template v-if="q.question.problem_answer_method === 7">
                        {{ $t('com.report.onlinetest.bct') }}
                      </template>
                    </span>
                    <span v-if="q.question.is_not_score && q.question.is_not_score === 1"> {{ $t('com.report.onlinetest.text015') }} </span>
                    <span v-else>
                      (<span style="color: rgb(24, 144, 255)">{{ q.question.actualscore }}</span>
                      <span>/</span>
                      <span>{{ $t('com.report.onlinetest.text031', [q.question.labelscore]) }}</span
                      >)
                    </span>
                  </div>
                  <question-content :content="translateIndex(q.question.problem, qIndex + 1)" />
                  <div class="obj-answer">
                    <div>
                      <span>{{ $t('com.report.onlinetest.text003') }}：</span>
                      <span v-if="!q.question.answer">{{ $t('com.report.onlinetest.text030') }}</span>
                      <!-- <span v-if="q.question.is_not_score && q.question.is_not_score === 1">{{ $t('com.report.onlinetest.text015') }}</span> -->
                    </div>
                    <pre v-html="q.question.answer"></pre>
                  </div>

                  <div v-if="q.question.answerFileList.length" class="attach-list">
                    <div v-for="(attach, index) in q.question.answerFileList" :key="index" class="attach-item flex btw">
                      <div class="flex flex1 overflow">
                        <i class="el-icon-paperclip mr5" />
                        <div class="overflow" :title="attach.fileName">{{ attach.fileName }}</div>
                      </div>
                      <div class="flex">
                        <span class="preview" @click="preview(attach.fileUrl)">
                          {{ $t('com.report.third.text007') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="q.question.problem_answer_method === 11">
                <div class="question">
                  <div class="question-type">
                    <span>{{ qIndex + 1 }}.{{ $t('com.report.onlinetest.text037') }}</span>
                  </div>
                  <div style="display: flex; justify-content: space-between; margin: 10px 0">
                    <div>
                      {{ $t('com.report.onlinetest.text037') }}：
                      <span style="color: #ffca3a">
                        {{ interviewResult.colorBlindess.resultStatus ? $t('com.report.onlinetest.text035') : $t('com.report.onlinetest.text036') }}
                      </span>
                    </div>
                    <div>
                      {{ $t('com.report.onlinetest.text033') }}：<span style="color: #999">{{ q.question.right_key }}</span>
                    </div>
                    <div>
                      {{ $t('com.report.onlinetest.text034') }}：<span style="color: #999">{{ q.question.answer }}</span>
                    </div>
                  </div>
                  <img :src="q.question.problem" height="200px" width="200px" alt="" srcset="" />
                </div>
              </div>
              <div
                data-title="客观题"
                v-if="
                  q.question.problem_answer_method === 2 ||
                  q.question.problem_answer_method === 3 ||
                  q.question.problem_answer_method === 4 ||
                  q.question.problem_answer_method === 5 ||
                  q.question.problem_answer_method === 8
                "
              >
                <div class="question" data-title="客观题">
                  <!-- <div class="question-content">
                    <pre v-html="translateIndex(q.question.problem, qIndex + 1)"></pre>
                  </div> -->
                  <div class="question-type">
                    <span>
                      {{ qIndex + 1 }}.
                      <template v-if="q.question.problem_answer_method === 2">
                        {{ $t('com.report.onlinetest.dxt') }}
                      </template>
                      <template v-if="q.question.problem_answer_method === 3">
                        {{ $t('com.report.onlinetest.ddxt') }}
                      </template>
                      <template v-if="q.question.problem_answer_method === 4">
                        {{ $t('com.report.onlinetest.pdt') }}
                      </template>
                      <template v-if="q.question.problem_answer_method === 5">
                        {{ $t('com.report.onlinetest.pxt') }}
                      </template>
                      <template v-if="q.question.problem_answer_method === 8">
                        {{ $t('com.report.onlinetest.tkt') }}
                      </template>
                    </span>
                    <span v-if="q.question.is_not_score && q.question.is_not_score === 1"> {{ $t('com.report.onlinetest.text015') }} </span>
                    <span v-else>
                      (<span style="color: rgb(24, 144, 255)">{{ q.question.actualscore }}</span>
                      <span>/</span>
                      <span>{{ $t('com.report.onlinetest.text031', [q.question.labelscore]) }}</span
                      >)
                    </span>
                  </div>
                  <question-content :content="translateIndex(q.question.problem, qIndex + 1)" />

                  <div class="sub-option gray" v-if="q.question.problem_answer_method != 4 && q.question.problem_answer_method != 8">
                    <div v-for="(op, ind) in q.questionOptionList" :key="ind">{{ op.optionCode }}、{{ op.optionContent }}</div>
                  </div>
                  <div class="sub-answer">
                    <div>
                      <span>{{ $t('com.report.onlinetest.text003') }}：</span>

                      <span :style="{ color: isXmzCompany || q.question.right_key == 'no' ? '' : getColor(q.question.objective_score) }">
                        <template v-if="q.question.problem_answer_method !== 8">
                          <span>{{ optionExtContent(q.question) }}</span>
                        </template>
                        <span v-if="!isXmzCompany && q.question.right_key !== 'no'">({{ rightText(q.question.objective_score) }})</span>
                      </span>
                      <!-- :class=" isXmzCompany ? '' : { green: q.question.objective_score === 1, red: !q.question.objective_score, yellow: q.question.objective_score === 2, }" -->
                    </div>
                    <div class="line-20" v-if="!isXmzCompany">
                      <span v-if="q.question.is_not_score && q.question.is_not_score === 1">{{ $t('com.report.onlinetest.text015') }}</span>
                    </div>

                    <template v-if="q.question.problem_answer_method === 8">
                      <div
                        v-for="(item, $index) in (q.question.answer || '').split(',')"
                        :key="$index"
                        style="margin-left: 24px"
                        :style="{ color: isXmzCompany ? '' : getColor(q.question.objective_score) }"
                      >
                        {{ $index + 1 }}: {{ item }}
                      </div>
                    </template>

                    <div class="line-20" v-if="!isXmzCompany">
                      <span>{{ $t('com.report.onlinetest.text004') }}：</span>
                      <span v-if="q.question.problem_answer_method !== 8"> {{ RightKey(q.question.right_key) }}</span>
                      <template v-else>
                        <div v-for="(item, $index) in q.question.right_key.split(',')" :key="$index" style="margin-left: 24px">{{ $index + 1 }}: {{ item }}</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <CopyrightNotice />
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
// import PercentWrapper from '@/components/reportV3/common/percent-wrapper.vue'
import Empty from '@/components/reportV3/common/empty.vue'
import QuestionContent from '@/components/reportV3/common/question-content.vue'
import EventBus from '@/common/event-bus'
export default {
  components: { CopyrightNotice, Empty, QuestionContent },
  props: {
    questionArray: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    correctNum() {
      return this.questionArray.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return info.question.objective_score === 1
      }).length
    },

    wrongNum() {
      return this.questionArray.filter(item => {
        const info = Array.isArray(item) ? item[0] : item
        return (
          (info.question.problem_answer_method === 2 ||
            info.question.problem_answer_method === 3 ||
            info.question.problem_answer_method === 4 ||
            info.question.problem_answer_method === 5 ||
            info.question.problem_answer_method === 8 ||
            info.question.problem_answer_method === 11) &&
          (!info.question.objective_score || info.question.objective_score !== 1) &&
          info.question.right_key !== 'no'
        )
      }).length
    },

    interviewResult() {
      return this.$store.state.interviewResult
    },

    isNewShareReport() {
      return this.$route.path === '/newShareReport' || this.$route.path === '/downloadReport'
    },

    isTclEnPostion() {
      const positionid = this.interviewResult?.positionid
      return this.$store.state.tclEnPostionIds.includes(positionid)
    },

    // currentPerson() {
    // 	return hologram.currentPerson
    // },

    isXmzCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.xmzCompanyId.includes(companyId)
    },
  },

  methods: {
    preview(attachUrl) {
      EventBus.$emit('openPreviewer', attachUrl)
    },
    optionExtContent(question) {
      if (!(question.optionExt || []).length) return question.answer
      let answerList = (question.answer || '').split(',')
      let res = []
      answerList.forEach(item => {
        const content = question.optionExt.find(ext => ext.optionCode === item)?.optionSupplementaryContent
        if (content) {
          const str = `${item}-${content}`
          res.push(str)
        } else {
          res.push(item)
        }
      })
      return res.join(',')
    },
    getColor(score) {
      if (score === 1) return '#6ed401'
      else if (score === 2) return '#f4d75e'
      else return '#ff0000'
    },
    getPlayerOption(video) {
      return {
        width: '100%',
        height: 165,
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{ type: 'video/mp4', src: video.video_url_mp4 ? video.video_url_mp4 : video.video_url }],
        poster: (video.video_url_mp4 ? video.video_url_mp4 : video.video_url) + '?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast', //require('./images/banner-poster.jpg'), // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
    onPlay(player) {
      console.log(player)
      const videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].onplay = () => {
            for (let j = 0; j < videoArray.length; j++) {
              if (i !== j) {
                videoArray[j].pause()
              }
            }
          }
        }
      }
    },
    rightText(key) {
      switch (key) {
        case 1:
          return this.$t('com.report.reportV3.text133')
        case 2:
          return this.$t('com.report.reportV3.text134')
        default:
          return this.$t('com.report.reportV3.text135')
      }
    },
    RightKey(rightKey) {
      if ('true' === rightKey) {
        return this.$t('com.report.reportV3.text136')
      } else if ('false' === rightKey) {
        return this.$t('com.report.reportV3.text137')
      } else if ('no' === rightKey) {
        return this.$t('com.report.reportV3.text138')
      }
      return rightKey
    },

    translateIndex(str) {
      // if (str && /<p.*?>/.test(str)) {
      //   str = str.replace(/<p.*?>/, '<p>' + index + '.')
      // } else {
      //   str = index + '.' + str
      // }
      str = str.replace(/<p>(&nbsp;)*<\/p>/g, '')
      // console.log('str', str)
      return str
    },
  },
}
</script>
<style lang="scss" scoped>
.read-exam-summary {
  margin-top: 0px;
}
.read-question_item {
  padding: 10px 10px;
  background: #f5f5f5;
  border-radius: 5px;
  margin: 5px;
}
.summary-title {
  font-size: 16px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.85);
  line-height: 22px;
  margin-bottom: 8px;
}
.line2 {
  font-size: 12px;
  color: #999999;
  margin-bottom: 40px;
}
.interview_time {
  width: 100%;
  margin: 10px 0px;
  padding: 10px;
  background: #f3f9ff;
  border-radius: 8px;
  .use_time {
    // margin-bottom: 10px;
    font-size: 14px;
    color: #333333;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    .trans_icon {
      color: #1890ff;
      .active_icon {
        transform: rotate(90deg);
      }
    }
    .clockimg {
      width: 16px;
      height: 16px;
      margin-right: 2px;
      position: relative;
      top: 3px;
    }
  }
  .time {
    // padding: 10px;
    // background: #f8f8f8;
    border-radius: 5px;
    font-size: 12px;
    color: #6d7278;
    .begin_time {
      span {
        font-weight: 400;
      }
    }
    .begin_time + .begin_time {
      margin-top: 5px;
    }
  }
}
.indicator-wrappper {
  position: relative;
  .get-score {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 15px;
    span {
      color: #1890ff;
    }
  }
}

.note-wrapper {
  // width: calc(100% - 50px);
  margin: 30px auto;
  padding: 10px;
  background-color: #f0f3f7;
  border-radius: 10px;

  .note-title {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
  }

  .note-content {
    margin-top: 10px;
    color: #333333;
    font-size: 14px;
    line-height: 20px;
  }
}

.answer-situation {
  margin-top: 20px;
  // padding-left: 28px;
  font-size: 16px;
  font-weight: 600;
  color: rgba(51, 51, 51, 0.85);
  line-height: 22px;
}

.answer-situation-wrapper {
  // width: calc(100% - 50px);
  height: 50px;
  margin: 16px auto;
  background: #ffffff;
  border-radius: 10px;
  border: 1px dashed #d6dde8;
  display: flex;
  align-items: center;
  padding: 0 20px 0 8px;
  // color: #afb6ca;
  font-size: 14px;
  justify-content: space-between;

  .answer-numer {
    display: flex;
    align-items: center;
    .right {
      .number {
        font-size: 18px;
        font-weight: 600;
        color: #1890ff;
      }
    }
    .wrong {
      margin-left: 56px;
      .number {
        font-size: 18px;
        color: #ff7575;
        font-weight: 600;
      }
    }
  }

  .show-answer-detail {
    cursor: pointer;
    font-size: 14px;
    color: #1890ff;
  }
}

.question-detail-wrapper {
  margin: 0px auto 0 auto;
  // width: calc(100% - 50px);
  .gray {
    color: #666666;
  }
  .green {
    color: #6ed401;
  }
  .red {
    color: #ff0000;
  }
  .yellow {
    color: #f4d75e;
  }
  .line-20 {
    line-height: 20px;
  }

  pre {
    word-break: break-word;
    white-space: pre-wrap;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  /deep/ .van-tabs {
    .van-tabs__line {
      background: #1890ff;
    }
    .van-tab--active {
      font-weight: 550;
    }
    .van-tabs__content {
      padding-bottom: 40px;
    }
  }
  .comlun {
    flex-direction: column;
    justify-content: space-between;
  }
  /deep/ .obj-pagination {
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    .van-pagination__page {
      flex-grow: 1;
    }
    li:last-child {
      display: none;
    }
    li:first-child {
      display: none;
    }
    li {
      cursor: pointer;
      //display: block;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      margin: 0 8px;
      border: 1px solid rgba(221, 221, 221, 1);
    }
  }
  .video-title {
    padding: 0 20px;
  }
  .obj-title {
    // padding: 0 20px;
    font-size: 12px;
    color: #666666;
  }
  .obj-answer {
    // margin: 0 20px;
    border: 1px dashed #dddddd;
    border-radius: 5px;
    padding: 12px 16px;
    padding: 14px;
    div:first-child {
      color: #333333;
      font-weight: 500;
      // font-size: 14px;
      // margin-bottom: 10px;
    }
    pre {
      margin: 0 !important;
      p {
        margin: 0 !important;
      }
    }
  }
  .sub-answer {
    border: 1px dashed #dddddd;
    border-radius: 5px;
    padding: 12px 16px;
    margin-top: 10px;
    div:first-child {
      color: #333333;
      font-weight: 500;
      // font-size: 14px;
      // margin-bottom: 10px;
    }
  }
  /deep/.van-panel {
    .van-panel__header {
      padding-top: 14px;
      padding-bottom: 14px;
      .van-cell__title {
        font-size: 15px;
        color: #333333;
        font-weight: 550;
      }
    }
    .van-panel__content {
      padding: 0 20px;
    }
  }
  /deep/ .van-panel::after {
    content: none;
  }
  .question {
    margin-top: 10px;
    /deep/ img {
      max-width: 100%;
    }
    .video-wrapper {
      // width: 50%;
      video {
        width: 100%;
      }
    }

    .question-type {
      font-weight: bold;
    }
  }
}
.attach-list {
  margin-top: 16px;
  .attach-item {
    // width: 100%;
    padding: 0 12px;
    background: #f5f5f5;
    border-radius: 2px;
    font-size: 14px;
  }
  .attach-item:hover {
    background: #e8f3ff;
  }
  .attach-item + .attach-item {
    margin-top: 8px;
  }
  .preview {
    padding: 10px 0px;
    margin-left: 12px;
    color: #1890ff;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .flex1 {
    flex: 1;
  }
  .btw {
    justify-content: space-between;
  }
  .overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mr5 {
    margin-right: 5px;
  }
}
</style>
<style lang="scss">
.obj-answer {
  pre {
    p {
      margin: 0 !important;
    }
  }
}
</style>
