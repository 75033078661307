<template>
  <div class="interview-record-content">
    <div ref="AiReport">
      <template v-if="isFinished">
        <div class="moduleTitle mt16">
          <img class="logou" src="@/assets/header-logo.jpg" alt="">
          <span>{{ $t('com.report.reportV3.text040') }}</span>
          <img class="un_fold" :class="{ 'roate180': isfold}" src="@/assets/fold_un.png" alt="" @click="unHfold">
        </div>
        <Summary v-show="tabIndex === 1 && !isfold" />
      </template>
      <div class="moduleTitle">
        <img class="logou" src="@/assets/header-logo.jpg" alt="">
        <span>{{ $t('com.report.reportV3.text144') }}</span>
        <img class="un_fold" :class="{ 'roate180': isfold0}" src="@/assets/fold_un.png" alt="" @click="unHfold0">
      </div>
      <VideoInterviewRecord v-show="tabIndex === 1 && !isfold0" />
      <template v-if="showAiDimenAnaly && isFinished && interviewResult.haveVideoQuestion === 1">
        <!-- AI维度分析 -->
        <div class="moduleTitle">
          <img class="logou" src="@/assets/header-logo.jpg" alt="">
          <span>{{ $t('com.report.reportV3.text145') }}</span>
          <img class="un_fold" :class="{ 'roate180': isfold1}" src="@/assets/fold_un.png" alt="" @click="unHfold1">
        </div>
        <SoftSkills v-show="tabIndex === 1 && !isfold1" />
      </template>
      <VoiceResult :aiData="aiData" v-show="aiData.haveise && tabIndex === 1 && isJdCompanyId & false" />
      <div class="aigrade">
        <div class="agtle">{{ $t('com.report.reportV3.text_aigd') }}</div>
        <div>
          <div class="agtag">L5</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l5') }}</div>
        </div>
        <div>
          <div class="agtag">L4</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l4') }}</div>
        </div>
        <div>
          <div class="agtag">L3</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l3') }}</div>
        </div>
        <div>
          <div class="agtag">L2</div>
          <div class="agtit">{{ $t('com.report.reportV3.text_l2') }}</div>
        </div>
      </div>
      <CopyrightNotice v-show="tabIndex === 1" />
    </div>
    <div ref="VideoInterviewRecord">
      <VideoInterviewRecord v-if="tabIndex === 0" />
    </div>
    <div ref="OtherQuestionRecord">
      <OtherQuestionRecord v-if="tabIndex === 2" />
    </div>
    <div></div>
  </div>
</template>
<script>
import EventBus from '@/common/event-bus'
import VideoInterviewRecord from '@/components/reportComponents/VideoInterviewRecord/index'
import OtherQuestionRecord from '@/components/reportComponents/OtherQuestionRecord'
import SoftSkills from '@/components/reportV3/soft-skills.vue'
import Summary from '@/components/reportV3/summary.vue'
import CopyrightNotice from '@/components/reportV3/common/copyright-notice.vue'
import VoiceResult from '@/components/reportV3/voice-result.vue'

export default {
  props: { scrollTop: { type: Number }, activeIndex: { type: Number }, tabBarHeight: { type: Number }, baseInfoHeight: { type: Number } },
  components: { VideoInterviewRecord, OtherQuestionRecord, SoftSkills, Summary, CopyrightNotice, VoiceResult },
  computed: {
    aiData() {
      return this.$store.state.aiData
    },
    token() {
      return this.$store.state.token
    },
    interviewId() {
      return this.$store.state.interviewId
    },
    isJdCompanyId() {
      const companyId = this.$store.state.companyId
      return this.$store.state.jdCompanyId.includes(companyId)
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },
    isFinished() {
      return this.interviewResult?.interview_status === 'finish'
    },
    showAiDimenAnaly() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_competency_score_reason')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 11)
      return config?.inUse === 0 ? false : true
    },
  },
  beforeDestroy() {
    EventBus.$off('updateActiveIndex', this.tapUpdateActiveIndex)
  },
  created() {
    EventBus.$on('updateActiveIndex', this.tapUpdateActiveIndex)
  },
  data() {
    return {
      videoInterviewRecordHeight: 0,
      tabIndex: 1,
      isfold: false,
      isfold0: false,
      isfold1: false,
    }
  },
  methods: {
    tapUpdateActiveIndex(val) {
      this.tabIndex = val
      // if (val === 0) {
      //   document.documentElement.scrollTop = 0
      //   document.body.scrollTop = 0
      // } else if (val === 1) {
      //   this.videoInterviewRecordHeight = this.$refs.VideoInterviewRecord.scrollHeight
      //   document.documentElement.scrollTop = this.videoInterviewRecordHeight + this.baseInfoHeight + 5
      //   document.body.scrollTop = this.videoInterviewRecordHeight + this.baseInfoHeight + 5
      // }
    },
    unHfold() {
      this.isfold = !this.isfold
    },
    unHfold0() {
      this.isfold0 = !this.isfold0
    },
    unHfold1() {
      this.isfold1 = !this.isfold1
    },
  },
  watch: {
    // scrollTop: {
    //   handler: function(val) {
    //     this.videoInterviewRecordHeight = this.$refs.VideoInterviewRecord.offsetHeight
    //     if (val >= this.videoInterviewRecordHeight + this.baseInfoHeight) {
    //       this.$emit('updateShowActiveIndex', 1)
    //     } else {
    //       this.$emit('updateShowActiveIndex', 0)
    //     }
    //   },
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.videoInterviewRecordHeight = this.$refs.VideoInterviewRecord.offsetHeight
    })
  },
}
</script>
<style lang="scss" scoped>
.moduleTitle {
  height: 48px;
  margin-top: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F6F7F8;
  .logou {
    width: 23px;
    height: 23px;
    margin-right: 5px;
  }
  span {
    font-size: 24px;
    font-weight: 500;
    color: #1890FF;
  }
  .un_fold {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    cursor: pointer;
  }
  .roate180 {
    transform: rotate(180deg);
  }
}
.mt16 {
  margin-top: 16px;
}
.aigrade {
  margin-top: 16px;
  padding: 16px;
  background: #f3f9fe;
  border-radius: 8px;
  .agtle {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }
  .agtag {
    width: 32px;
    height: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // line-height: 20px;
    // text-align: center;
    border-radius: 4px;
    background: linear-gradient(90deg, #35c3ff 0%, #1890ff 100%);
  }
  .agtit {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    margin: 4px 0px 8px;
  }
}
</style>
