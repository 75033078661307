<template>
  <div class="objective-problem">
    <div v-if="currentModuleQuestionList && currentModuleQuestionList.length">
      <template v-for="(questionItems, index) in currentModuleQuestionList">
        <template v-for="(questionItem, $index) in Array.isArray(questionItems) ? questionItems : [questionItems]">
          <div :key="index + '-' + $index">
            <div class="question-type">
              <span> {{ index + 1 }}.{{ getTypeName(questionItem.question.problem_answer_method) }} </span>

              <span v-if="questionItem.question.is_not_score && questionItem.question.is_not_score === 1"> {{ $t('com.report.onlinetest.text015') }} </span>
              <span v-else>
                (<span style="color: rgb(24, 144, 255)">{{ questionItem.question.actualscore }}</span>
                <span>/</span>
                <span>{{ $t('com.report.onlinetest.text031', [questionItem.question.labelscore]) }}</span
                >)
              </span>
            </div>

            <div v-if="questionItem.question.problem_answer_method == 11" style="display: flex;justify-content: space-between;margin: 10px 0;">
              <div>
                {{ $t('com.report.onlinetest.text037') }}：
                <span style="color: #FFCA3A;">
                  {{ $store.state.interviewResult.colorBlindess.resultStatus?$t('com.report.onlinetest.text035'):$t('com.report.onlinetest.text036') }}
                </span>
              </div>
              <div>
                <span style="color: #999;">{{ $t('com.report.onlinetest.text033') }}：</span>{{questionItem.question.right_key}}
              </div>
              <div>
                <span style="color: #999;">{{ $t('com.report.onlinetest.text034') }}：</span>{{questionItem.question.answer}}
              </div>
            </div>
            <img v-if="questionItem.question.problem_answer_method == 11" :src="questionItem.question.problem" height="200px" width="200px" alt="" srcset="">

            <HtmlParse v-else :htmlStr="questionItem.question.problem" :show-label="false" :use-question-content="true" />
            <div class="option-wrapper" v-if="questionItem.questionOptionList && questionItem.questionOptionList.length && judgeType.includes(questionItem.question.problem_answer_method)">
              <div v-for="opt in questionItem.questionOptionList" :key="opt.optionId">{{ opt.optionCode }}、{{ opt.optionContent }}</div>
            </div>
            <div class="question-answer" v-if="questionItem.question.answer && questionItem.question.problem_answer_method != 11">
              <div style="align-items: center" :style="{ display: questionItem.question.problem_answer_method === 8 ? 'block' : 'flex' }">
                <div style="white-space: nowrap">{{ $t('com.report.onlinetest.text003') }}：</div>
                <pre
                  v-if="questionItem.question.problem_answer_method !== 8"
                  @click="downLoadClick"
                  :class="
                    questionItem.question.right_key === 'no'
                      ? ''
                      : {
                          green: questionItem.question.objective_score === 1,
                          red: !questionItem.question.objective_score,
                          yellow: questionItem.question.objective_score === 2,
                        }
                  "
                  v-html="optionExtContent(questionItem.question)"
                  style="margin: 0"
                ></pre>
                <div v-else style="margin-left: 24px">
                  <div
                    v-for="(item, $index) in (questionItem.question.answer || '').split(',')"
                    :key="$index"
                    :class="{
                      green: questionItem.question.objective_score === 1,
                      red: !questionItem.question.objective_score,
                      yellow: questionItem.question.objective_score === 2,
                    }"
                  >
                    {{ $index + 1 }}: {{ item }}
                  </div>
                </div>
              </div>
              <div :style="{ display: questionItem.question.problem_answer_method === 8 ? 'block' : 'flex' }" class="right-answer-wrapper">
                <div>{{ $t('com.report.onlinetest.text004') }}：</div>
                <div v-if="questionItem.question.problem_answer_method === 4">
                  {{ questionItem.question.right_key === 'true' ? $t('com.report.onlinetest.text005') : $t('com.report.onlinetest.text006') }}
                </div>
                <div v-else-if="questionItem.question.problem_answer_method === 8" style="margin-left: 24px">
                  <div v-for="(item, $index) in questionItem.question.right_key.split(',')" :key="$index">{{ $index + 1 }}: {{ item }}</div>
                </div>
                <div class="object-right-key" v-else>{{ questionItem.question.right_key === 'no' ? $t('com.report.reportV3.text138') : questionItem.question.right_key }}</div>
              </div>
            </div>
            <br />
          </div>
        </template>
      </template>
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
// import { Vue, Component } from 'vue-property-decorator'
// import HtmlParse from '@/views/hologram/ai-interview/HtmlParse.vue'
// import hologram from '@/store/modules/hologram'
import { saveAs } from 'file-saver'
import HtmlParse from '@/components/HtmlParse'
import Empty from '@/components/reportV3/common/empty.vue'

// @Component({ )
export default {
  components: { HtmlParse, Empty },
  data() {
    return {
      types: [2, 3, 4, 5, 8, 11],
      judgeType: [2, 3, 5],
    }
  },

  computed: {
    //获取当前模块题目
    currentModuleQuestionList() {
      // eslint-disable-next-line camelcase
      return this.answerResult.filter(r => {
        const info = Array.isArray(r) ? r[0] : r
        return this.types.includes(+info.question?.problem_answer_method)
      })
    },

    //获取所有题目
    answerResult() {
      return this.$store.state.answerResult
    },
  },
  methods: {
    downLoadClick(e) {
      const target = e?.target
      if (target?.nodeName === 'A') {
        if (target.hasAttribute('href')) {
          e.preventDefault()
          const href = target.getAttribute('href') || ''
          const fileName = target.innerText
          saveAs(href, fileName)
        }
      }
      console.log('Event', e)
    },
    optionExtContent(question) {
      if (!(question.optionExt || []).length) return question.answer
      let answerList = (question.answer || '').split(',')
      let res = []
      answerList.forEach(item => {
        const content = question.optionExt.find(ext => ext.optionCode === item)?.optionSupplementaryContent
        if (content) {
          const str = `${item}-${content}`
          res.push(str)
        } else {
          res.push(item)
        }
      })
      return res.join(',')
    },
    getTypeName(type) {
      const types = [
        { value: 1, label: `${this.$t('com.report.onlinetest.spt')}` },
        { value: 0, label: `${this.$t('com.report.onlinetest.lst')}` },
        { value: 2, label: `${this.$t('com.report.onlinetest.dxt')}` },
        { value: 3, label: `${this.$t('com.report.onlinetest.ddxt')}` },
        { value: 8, label: `${this.$t('com.report.onlinetest.tkt')}` },
        { value: 4, label: `${this.$t('com.report.onlinetest.pdt')}` },
        { value: 5, label: `${this.$t('com.report.onlinetest.pxt')}` },
        { value: 7, label: `${this.$t('com.report.onlinetest.bct')}` },
        { value: 6, label: `${this.$t('com.report.onlinetest.kyt')}` },
        { value: 11, label: `${this.$t('com.report.onlinetest.text037')}` },
      ]
      const obj = types.find(item => item.value === type)
      if (obj) {
        return obj.label
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.question-title,
.question-answer {
  .objective-answer {
    pre {
      font-size: 16px;
    }
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', 'WenQuanYi Micro Hei', sans-serif;
    // display: flex;
    // align-items: baseline;
    img {
      max-width: 100%;
    }
  }

  .green {
    color: #6ed401;
  }
  .red {
    color: #ff0000;
  }
  .yellow {
    color: #f4d75e;
  }
}

.question-type {
  margin-top: 8px;
  font-weight: bold;
}

.question-answer {
  margin-top: 5px;
  padding: 12px 16px;
  border-radius: 5px;
  // background: #f8f8fa;
  border: 1px dashed #dddddd;

  .answer-detail {
    // padding-left: 20px;
    display: flex;
    color: #c1cad9;
    align-items: center;
    margin-bottom: 10px;
    .question-tag {
      border-radius: 2px;
      background-color: #5facff;
      color: #ffffff;
      padding: 3px 12px;
    }
  }

  .right-answer-wrapper {
    .right-key {
      padding-left: 20px;
    }
  }
}
.option-wrapper {
  padding-left: 20px;
}
</style>
