<template>
  <div class="aigc-main">
    <Header />
    <div class="aigc-main_body">
      <div v-for="(img, index) in imgList" :key="index">
        <img :src="img.url" :width="img.width || '100%'" :style="{ 'margin-bottom': img.mb || '0' }" v-if="!img.mode" />
        <div class="tabsver" v-if="img.mode && img.mode === 'xuexi'">
          <div class="tabt">
            <div class="itm" :class="{ 'selspa': cur === i }" v-for="i in 7" :key="i" @click="cur = i">
              <span>A{{ i }}</span>
            </div>
          </div>
          <div class="tabb">
            <img :src="img.childs[cur - 1]" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/menu'
export default {
  components: { Header },
  data() {
    // https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x2x7.png
    return {
      // imgList: [
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x1_1.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x2.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x3.png',
      //   // 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x4.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/dagang_title.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/1%402x.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/2%402x.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/3%402x.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/4%402x.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/5%402x.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/case.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/4999_9999.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/4999.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/9999.png',

      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x5.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x6.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x7.png',
      //   'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x8_1.png',
      // ],
      cur: 1,
      imgList: [
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x1_1.png', width: '100%' },
        { url: 'https://aimianshiguan.oss-accelerate.aliyuncs.com/resume/67302377609841802.png', width: '100%', mb: '-30px' },
        // { url: 'https://aimianshiguan.oss-accelerate.aliyuncs.com/resume/67259842782751669.png', width: '100%' },
        { url: 'https://aimianshiguan.oss-accelerate.aliyuncs.com/resume/82961198165120940.png', width: '100%' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x2.png', width: '100%' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x3.png', width: '100%' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/xxpath%402x_m.png', width: '100%' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/A1_A7%402x_m.png', width: '90%' },
        // {
        //   mode: 'xuexi',
        //   width: '90%',
        //   childs: [
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A1_m.png',
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A2_m.png',
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A3_m.png',
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A4_m.png',
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A5_m.png',
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A6_m.png',
        //     'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/ladder_A7_m.png',
        //   ],
        // },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/dagang_title.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/1%402x.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/2%402x.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/3%402x.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/4%402x.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/5%402x.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/case.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/4999_9999.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/4999.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/9999.png', width: '90%', mb: '0.4rem' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x5.png', width: '100%' },
        // { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x6.png', width: '100%' },
        { url: 'https://aimianshiguan.oss-accelerate.aliyuncs.com/resume/67262428619617742.png', width: '100%' },
        { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x7.png', width: '100%' },
        { url: 'https://aimianshiguan.oss-cn-beijing.aliyuncs.com/image/hppc/m2x8_1.png', width: '100%' },
      ],
    }
  },
  mounted() {
    document.querySelector('html').style.height = `${window.innerHeight}px`
    document.title = 'AIGC大模型培训'
  },
}
</script>
<style lang="scss" scoped>
.aigc-main {
  .aigc-main_body {
    // padding-top: 60px;
    text-align: center;
    .img {
      width: 100%;
    }
    .tabsver {
      .tabt {
        margin: 24px 0px 12px;
        padding: 0 4% 0 5%;
        display: flex;
        flex-wrap: nowrap;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
        .itm {
          width: 60px;
          height: 32px;
          font-size: 16px;
          color: #1990FF;
          flex: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
          border-radius: 16px;
          background: #e8f4ff;
          &:last-child {
            margin-right: 0px;
          }
        }
        .selspa {
          color: #fff;
          background: #1990FF;
        }
      }
      .tabb {
        img {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>