export default {
  text001: '论述题',
  text002: '笔试报告',
  text003: 'TA的回答',
  text004: '正确答案',
  text005: '正确',
  text006: '错误',

  text007: '在线考试',
  text008: '该模块涵盖了候选人在以下所有题目类别上的答题情况，包括：单选题，多选题，判断题，排序题，论述题。',
  text009: '{name}得分：{score}/{total}',
  text010: '备注：',
  text011: '选择题，判断题，排序题的分数只有在设定好题目分数以及标准答案后，才会涵盖在图表的总分当中。论述题的分数只有在设定好题目分数以及面试官评价过后，才会涵盖在图表的总分当中。',
  text012: '答题情况',
  text013: '正确题数：',
  text014: '错误题数：',
  text015: '( 本题不计分 )',
  text016: '没有内容可以查看',
  text017: '回答不全',
  text018: '回答错误',
  text019: '回答正确',

  text030: '候选人未作答',
  text031: '{0}分',
  text032: '检测结果',
  text033: '正确答案',
  text034: '候选人答案',
  text035: '合格',
  text036: '不合格',
  text037: '色盲检测',

  spt: '视频题',
  lst: '论述题',
  dxt: '单选题',
  ddxt: '多选题',
  tkt: '填空题',
  pdt: '判断题',
  pxt: '排序题',
  bct: '编程题',
  kyt: '口音题',
}
