<template>
  <div class="report-detail">
    <section v-if="!sleep">
      <!-- 视频题 -->
      <div v-if="videoProblemArray && videoProblemArray.length">
        <template v-for="(question, index) in videoProblemArray">
          <problem-item :currentQuestion="question.baseInfo" :currentQuestionIndex="index" :key="question.id"></problem-item>
          <template v-if="question.probeInfo && question.probeInfo.length">
            <problem-item v-for="probe in question.probeInfo" :currentQuestion="probe" :currentQuestionIndex="index" :question-label="getQuestionLabel(probe)" :is-probe="true" :key="'probe-' + probe.id"></problem-item>
          </template>

          <div class="danti" v-if="aiMarkCfg.singleStatus " :key="index">
            <div class="khsrl">
              {{ $t('com.report.interviewRecord.text004') }}：
              <span v-if="question.baseInfo.question.label_name">{{ question.baseInfo.question.label_name }}</span>
            </div>
            <div class="flexdiv">
              <div class="label">{{ $t('com.report.interviewRecord.text006') }}：</div>
              <template v-if="aiMarkCfg.singleStatus && !l2status && getProblem(question.baseInfo.question) && getProblem(question.baseInfo.question).calculateStar > -1">
                <van-rate
                  v-model="getProblem(question.baseInfo.question).calculateStar"
                  color="#ffd21e"
                  :size="15"
                  void-icon="star"
                  :gutter="5"
                  void-color="#eee"
                  readonly
                  :count="aiData.isL5Position === 1 ? 3 : 5"
                />
                <span class="huase" v-if="getProblem(question.baseInfo.question).calculateStar == 1">{{ $t('com.report.interviewRecord.text51') }}</span>
                <span class="huase" v-if="getProblem(question.baseInfo.question).calculateStar == 2">{{ $t('com.report.interviewRecord.text52') }}</span>
                <span class="huase" v-if="getProblem(question.baseInfo.question).calculateStar == 3">{{ $t('com.report.interviewRecord.text53') }}</span>
              </template>
              <span v-else-if="l2status">{{ $t('com.report.interviewRecord.text007') }}</span>
              <van-rate v-else :value="0" color="#ffd21e" :size="15" void-icon="star" :gutter="5" void-color="#eee" readonly :count="aiData.isL5Position === 1 ? 3 : 5"></van-rate>
            </div>
            <div v-for="(item, idx) in dimLabelList" :key="idx">
              <template v-if="item.assessmentPoint === question.baseInfo.question.label_name">
                <div v-if="item.scoreReason" class="spcont">
                  <div class="spctit">{{ $t('com.report.reportV3.text153') }}</div>
                  <div class="spcdtil">
                    <span v-html="`${item.scoreReason}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
                  </div>
                </div>
                <template v-if="showNextSuugest && item.isConfigNextSuggest === 1">
                  <el-divider v-if="item.scoreReason" />
                  <div class="spcont">
                    <div class="spctit">{{ $t('com.report.reportV3.text148') }}</div>
                    <div v-if="item.nextSuggest" class="spcdtil">
                      <span v-html="`${item.nextSuggest}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
                    </div>
                    <div
                      v-else
                      v-loading="true"
                      class="hei30"
                      :element-loading-text="$t('com.report.reportV3.text004')"
                      element-loading-spinner="el-icon-loading"
                      element-loading-background="rgba(0, 0, 0, 0)"
                    />
                  </div>
                </template>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="emptydiv" v-else>
        <img class="novido" src="@/assets/novideo.png" alt="" />
        <div class="tip">{{ $t('com.report.interviewRecord.text015_') }}</div>
      </div>
      <!-- <van-empty :description="$t('com.report.interviewRecord.text015')" v-else /> -->
    </section>
  </div>
</template>

<script>
import { listInterviewOperLog } from '@/api/report'

import ProblemItem from './problem-item'
export default {
  components: { ProblemItem },
  props: [],
  inject: {
    provideInfo: { value: 'provideInfo', default: null },
  },
  data() {
    return {
      sleep: false,
      videoProblemArray: [],
      active: 0,
    }
  },
  computed: {
    answerResult() {
      return this.$store.state.answerResult || []
    },
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    l2status() {
      //0已出分 1 出分中
      return this.aiData?.l2ReportInfo?.l2status
    },
    aiData() {
      return this.$store.state.aiData
    },
    dimLabelList() {
      return this.aiData?.l2ReportInfo?.dimLabelList?.filter(e => e.assessmentPoint && +e.calculateStar >= 0)
    },
    answerInfos() {
      return this.aiData?.l2ReportInfo?.answerInfos || []
    },
    showNextSuugest() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_competency_suggest')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 12)
      return config?.inUse === 0 ? false : true
    },
  },
  methods: {
    getProblem(problem) {
      return this.answerInfos.find(p => {
        return problem?.label_name === p?.assessmentPoint
      })
    },
    initVideoArray() {
      this.videoProblemArray = this.answerResult
        .filter(item => {
          const info = Array.isArray(item) ? item[0] : item
          return info.question.problem_answer_method === 1 || info.question.problem_answer_method === 6
        })
        .map(item => {
          const isArray = Array.isArray(item)
          const info = isArray ? item[0] : item
          const probeInfo = isArray
            ? item
                .filter((p, i) => {
                  return i > 0
                })
                .map(p => {
                  return { ...p, logList: [], viewVisible: item[1] ? false : true }
                })
            : undefined
          // (item[1] ? { ...item[1], logList: [], viewVisible: item[1] ? false : true } : undefined)
          // { ...item[1], logList: [], viewVisible: item[1] ? false : true }
          return {
            baseInfo: { ...info, logList: [], viewVisible: item[1] ? false : true },
            probeInfo: probeInfo,
          }
        })
    },
    getlistInterviewOperLog() {
      listInterviewOperLog({ interviewId: this.interviewId }).then(res => {
        if (res && res.code === 0) {
          this.videoProblemArray.forEach(item => {
            let logList = res.data.filter(logItem => {
              return logItem.problemAnswerId === item.question.id
            })
            item.baseInfo.logList = logList
          })
        }
      })
    },
    getQuestionLabel(question) {
      const interviewRoundNum = question.interviewRoundNum
      const label = this.$t('com.report.interviewRecord.text047')
      return interviewRoundNum > 0 ? `(${label}-${interviewRoundNum}) ` : `(${label}) `
    },
  },
  created() {},
  watch: {
    answerResult: {
      deep: true,
      immediate: true,
      handler: function () {
        this.initVideoArray()
      },
    },
    videoProblemArray() {
      this.getlistInterviewOperLog()
    },
  },
}
</script>

<style lang="scss" scoped>
.danti {
  margin-top: 16px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #dddddd;
  .khsrl {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
  }
  .flexdiv {
    display: flex;
    margin: 8px 0 12px;
    .label {
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      margin-right: 10px;
    }
    .van-rate {
      position: relative;
      top: 2px;
    }
    .huase {
      font-weight: 400;
      font-size: 14px;
      color: #ffca3a;
      line-height: 20px;
      margin-left: 8px;
    }
  }
  .el-divider {
    margin: 16px 0;
  }
  .spctit {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    margin-bottom: 4px;
  }
  .spcdtil {
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 22px;
  }
}
.emptydiv {
  text-align: center;
  .novido {
    width: 120px;
    height: 120px;
  }
  .tip {
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
  }
}
</style>
